import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

export const getBlacklist = async (data) => {
  try {
    const response = await client.post('/blacklist/getBlacklist', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getUserByData = async (data) => {
  try {
    // console.log('data: ', data)
    const response = await client.post('/blacklist/getUserByData', { data });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const addUsersAndFileToBlacklist = async (data) => {
  try {
    console.log('data: ', data)
    const response = await client.post('/blacklist/addUsersAndFileToBlacklist', { data });
    return response.data
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const downloadBlacklist = async (data) => {
  try {
    const response = await client.post('/blacklist/downloadBlacklist', data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const confirmAddBlacklist = async (data, file) => {
  try {
    const payload = { data, file };
    const response = await client.post('/blacklist/confirmAddBlacklist', payload);
    return response.payload;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const confirmRemoveBlacklist = async (data, file) => {
  try {
    const payload = { data, file };
    const response = await client.post('/blacklist/confirmRemoveBlacklist', payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getDate = async (data) => {
  try {
    const response = await client.post('/blacklist/getDate', data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const exportBlacklist = async (data) => {
  try {
    const response = await client.post('/blacklist/exportBlacklist', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
