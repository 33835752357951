import client from './instance'
import client2 from './instanceNoLodding'
 

export const migrationC365 = async (data) => {
  try {
    const response = await client.post('/import/migrationC365', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};
export const getParameterContent = async (data) => {
  try {
    const response = await client2.post('/import/getParameterContent', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export  const getDataDate = async () => {
  try {
    const response = await client2.post('/import/get-data-date' );
    return response;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getListScheduleData = async (data) => {
  try{
    const response = await client.post('/import/list-schedule', data);
    return response;
  }
  catch(error){
    console.log(error)
    throw error;
  }
}

export const holdSchedule = async (data) => {
  try{
    const response = await client.post('/import/hold-schedule', data);
    return response;
  }
  catch(error){
    console.log(error)
    throw error;
  }
}

export const processSchedule = async (data) => {
  try{
    const response = await client.post('/import/process-schedule', data);
    return response;
  }
  catch(error){
    console.log(error)
    throw error;
  }
}